
import Vue from 'vue'
import { variables } from './plugins/variables'
import confirm from '@/components/Confirm.vue'
import { nouveauJeton } from './plugins/jwt'

interface IConfirmer extends Vue {
  openDialogConfirm(title: string, message: string, options: object): Promise<boolean>
}
declare module 'vue/types/vue' {
  // 3. Déclarez l'augmentation pour Vue
  interface Vue {
    confirmation: (title: string, message: string, options: object) => Promise<boolean>
  }
}

export default Vue.extend({
  name: 'App',
  components: { confirm },
  data() {
    return {
      mini: false,
      contentWidth: 0,
      contentHeight: 0,
      unsubscribe: null as any,
      items: [] as any[],
      optionGestion: [] as any[],
      utilisateur: { user: 'non authentifié', ut_id: 0, administrateur: false },
      autorisations: '',
      authentifie: variables.authentifie
    }
  },
  computed: {
    drawer(): boolean {
      return this.authentifie
    }
  },
  created() {
    this.utilisateur.user = this.$store.state.utilisateur.user
    this.utilisateur.ut_id = this.$store.state.utilisateur.ut_id
    this.utilisateur.administrateur = this.$store.state.utilisateur.administrateur
    this.autorisations = this.$store.state.autorisations
  },
  methods: {
    reduire() {
      this.mini = false
      this.$nextTick(function () {
        this.onResize()
      })
    },
    toggleDrawer() {
      this.mini = !this.mini
    },
    drawerOff() {
      this.mini = true
    },
    onResize() {
      this.contentWidth = window.innerWidth
      this.contentHeight = window.innerHeight
      if (this.mini) {
        this.contentWidth -= 56
      } else {
        this.contentWidth -= 256
      }
      this.$store.commit('majContentHeight', { width: this.contentWidth, height: this.contentHeight })
    },
    ongletAccessible() {
      this.items = []
      this.optionGestion = [{ title: 'A propos...', icon_color: 'light-blue', icon: 'mdi-information-outline', path: '/about' }]
      const onglets = [
        { autorisation: 'Planning chantier', title: 'Planning chantier', icon_color: 'white', icon: 'mdi-calendar-check', path: '/planning/secteurs' },
        { autorisation: 'Consultation Planning', title: 'Consultation Planning', icon_color: 'blue', icon: 'mdi-calendar-check', path: '/planningconsultation' },
        { autorisation: 'Pointage mensuel', title: 'Pointage mensuel', icon_color: 'green', icon: 'mdi-account', path: '/pointage' },
        { autorisation: 'Pointage journalier', title: 'Pointage journalier', icon_color: 'green', icon: 'mdi-calendar-clock', path: '/pointageJour' },
        { autorisation: 'Blooms', title: 'Blooms', icon_color: 'white', icon: 'mdi-widgets', path: '/blooms' },
        { autorisation: 'Suivis ferraille', title: 'Suivi ferraille', icon_color: 'blue', icon: 'mdi-tag-text-outline', path: '/ferraille' },
        { autorisation: 'Coulées Ascométal', title: 'Coulées Ascométal', icon_color: 'yellow', icon: 'mdi-format-color-fill', path: '/coulees' },
        { autorisation: 'Rapport activité', title: "Rapport d'activité", icon_color: 'orange', icon: 'mdi-book-open', path: '/RapportActivite' },
        { autorisation: 'Comptage palette', title: 'Comptage palette', icon_color: 'green', icon: 'mdi-file-excel', path: '/ComptagePalette' },
        { autorisation: 'Nombre poste', title: 'Nombre poste', icon_color: 'white', icon: 'mdi-account', path: '/NbPoste' },
        { autorisation: 'Regroupement', title: 'Regroupement', icon_color: 'orange', icon: 'mdi-forklift', path: '/paletteFardeau' },
        { autorisation: 'Maintenances', title: 'Maintenances', icon_color: 'orange', icon: 'mdi-tools', path: '/iframe/maintenances' },
        { autorisation: 'Rapport Evacuation', title: 'Rapport évacuation', icon_color: 'green', icon: 'mdi-file-document-check-outline', path: '/rapportEvacuation' }
      ]
      onglets.forEach((item) => {
        if (variables.autorisation.includes(item.autorisation)) {
          this.items.push(item)
        }
      })
      if (variables.autorisation.includes('Administration')) {
        this.optionGestion = [
          { title: 'Salariés', icon_color: 'blue', icon: 'mdi-account', path: '/salaries' },
          { title: 'Autorisation', icon_color: 'red', icon: 'mdi-key', path: '/autorisations' },
          { title: 'Machines', icon_color: 'white', icon: 'mdi-dump-truck', path: '/iframe/machines' },
          { title: 'Appli Machines...', icon_color: 'white', icon: 'mdi-dump-truck', path: 'applicationMachines()' },
          { title: 'Activités-Compétences', icon_color: 'green', icon: 'mdi-vector-combine', path: '/activites' },
          { title: 'Liste diffusion', icon_color: 'blue', icon: 'mdi-email', path: '/listeDiffusion' },
          { title: 'A propos', icon_color: 'light-blue', icon: 'mdi-information-outline', path: '/about' }
        ]
      }
    },
    applicationMachines() {
      const jwt = nouveauJeton()
      const url = 'https://machines.mdsp.link/jwt/' + jwt
      window.open(url, '_blank')
    },
    ouvrirPage(path: any) {
      if (path == 'applicationMachines()') {
        // peut mieux faire...
        this.applicationMachines()
        return
      }
      this.$router.push(path)
    },
    logout() {
      variables.authentifie = false
      this.authentifie = false
      this.utilisateur.user = ''
      this.reduire()
      this.$router.push('/login')
    }
  },
  provide() {
    return {
      confirmation: (titre: string, message: string, options: Record<string, unknown>) => (this.$refs.confirm as IConfirmer).openDialogConfirm(titre, message, options)
    }
  },
  mounted() {
    this.onResize()
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'majUtilisateur') {
        this.utilisateur.user = state.utilisateur.user
        this.utilisateur.ut_id = state.utilisateur.ut_id
        this.utilisateur.administrateur = state.utilisateur.administrateur
        this.authentifie = variables.authentifie
      }
      if (mutation.type === 'majAutorisation') {
        this.autorisations = state.autorisations
        this.ongletAccessible()
      }
    })
    this.ongletAccessible()
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
})
