import Vue from 'vue'
import Router from 'vue-router'

import { variables } from './plugins/variables'
Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/blooms',
      component: () => import('./views/Blooms.vue')
    },
    {
      path: '/planning',
      name: 'planning',
      component: () => import('./views/planning.vue'),
      children: [
        {
          path: 'salaries',
          component: () => import('./views/plgSalaries.vue')
        },
        {
          path: 'jours',
          component: () => import('./views/plgJours.vue')
        },
        {
          path: 'secteurs',
          component: () => import('./views/plgSecteurs.vue')
        }
      ]
    },
    {
      path: '/planningconsultation',
      name: 'consultaionPlanning',
      component: () => import('./views/PlanningConsultation.vue')
    },
    {
      path: '/pointage',
      name: 'pointage',
      component: () => import('./views/Pointage.vue')
    },
    {
      path: '/pointageJour',
      name: 'pointageJour',
      component: () => import('./views/PointageJour.vue')
    },
    {
      path: '/ferraille',
      component: () => import('./views/suiviFerraille.vue')
    },
    {
      path: '/coulees',
      component: () => import('./views/Coulees.vue')
    },
    {
      path: '/salaries',
      name: 'salaries',
      component: () => import('./views/gestionSalaries.vue')
    },
    {
      path: '/iframe/:page',
      name: 'iframe',
      component: () => import('./views/IFrame.vue')
    },
    {
      path: '/activites',
      name: 'Activites',
      component: () => import('./views/Activites.vue')
    },
    {
      path: '/autorisations',
      name: 'Autorisations',
      component: () => import('./views/Autorisations.vue')
    },
    {
      path: '/RapportActivite',
      name: 'RapportActivite',
      component: () => import('./views/RapportActivite.vue')
    },
    {
      path: '/ComptagePalette',
      name: 'ComptagePalette',
      component: () => import('./views/ComptagePalette.vue')
    },
    {
      path: '/NbPoste',
      name: 'NbPoste',
      component: () => import('./views/NbPoste.vue')
    },
    {
      path: '/listeDiffusion',
      name: 'listeDiffusion',
      component: () => import('./views/listeDiffusion.vue')
    },
    {
      path: '/paletteFardeau',
      name: 'PaletteFardeau',
      component: () => import('./views/PaletteFardeau.vue')
    },
    {
      path: '/rapportEvacuation',
      name: 'RapportEvacuation',
      component: () => import('./views/RapportEvacuation.vue')
    },
    {
      path: '/accueil',
      name: 'Accueil',
      component: () => import('./views/Accueil.vue')
    },
  ]
})
router.beforeEach((to, from, next) => {
  if (variables.authentifie || to.path == '/' || to.path.startsWith('/login')) {
    next()
  } else {
    next({
      path: '/',
      params: { nextUrl: to.fullPath }
    })
  }
})

export default router
